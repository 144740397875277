<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공사현장 -->
          <c-plant type="search" :changeItem="changeItem" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :afterIcon="
              [
                {
                  name: 'search',
                  click: true,
                  callbackName: 'searchWorkPermit',
                },
                {
                  name: 'close',
                  click: true,
                  callbackName: 'removeWorkPermit',
                },
              ]
            "
            :editable="editable"
            :readonly="true"
            :changeItem="changeItem"
            label="작업허가번호"
            name="permitNo"
            v-model="searchParam.permitNo"
            @searchWorkPermit="searchWorkPermit"
            @removeWorkPermit="removeWorkPermit"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            :plantCd="searchParam.plantCd"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-project-location
            :editable="editable"
            :disabled="isWorkPermit || disabled"
            :plantCd="searchParam
            .plantCd"
            label="작업장소"
            name="sopMapId"
            v-model="searchParam.sopMapId"
          />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- :editable="editable && !isVendor" -->
          <c-vendor
            :editable="editable"
            :plantCd="searchParam.plantCd"
            :changeItem="changeItem"
            label="업체"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작성상태 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="TBM 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group v-if="isPlant" outline>
              <c-btn label="등록" icon="add" @btnClicked="linkClick" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
            <q-btn-group v-else outline>
              <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
                ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
              </font>
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'tbm',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            style: 'width:170px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            label: 'TBM번호',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:150px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            label: '작업일',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmCompleteFlagName',
            field: 'tbmCompleteFlagName',
            label: '작성상태',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            style: 'width:120px',
            label: '공종',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:150px',
            label: '업체',
            align: 'left',
            sortable: true,
          },
          {
            name: 'sopName',
            field: 'sopName',
            style: 'width:120px',
            label: '작업',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmWorkResponsibleName',
            field: 'tbmWorkResponsibleName',
            label: '작업책임자',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'workUserCount',
          //   field: 'workUserCount',
          //   label: '외',
          //   style: 'width:40px',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'permitTypeName',
          //   field: 'permitTypeName',
          //   label: '허가서구분',
          //   style: 'width:80px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'workTime',
            field: 'workTime',
            label: '작업시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      period: ['', ''],
      searchParam: {
        startYmd: '',
        endYmd: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        mdmSopId: '',
        sopName: '',
        permitNo: '',
        sopWorkPermitId: '',
        sopMapId: '',
        mapName: '',
        tbmCompleteFlag: null,

      },
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      editable: true,
      isVendor: false,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      if (this.$store.getters.user.innerFlag === 'N') {
        this.searchParam.vendorCd = this.$store.getters.user.deptCd;
        this.isVendor = true;
      }
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }

      this.listUrl = selectConfig.sai.tbm.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.detailPopup(row, col);
    },
    detailPopup(result, col) {
      if (col && col.name === 'permitNo') {
        this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: result ? result.sopWorkPermitId : '',
          permitTypeCd: result ? result.permitTypeCd : '',
        };
      } else {
        this.popupOptions.target = () =>
        import(`${"./tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세';
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.searchParam.sopWorkPermitId = data[0].sopWorkPermitId;
        this.searchParam.permitNo = data[0].permitNo;
      }
    },
    removeWorkPermit() {
      this.searchParam.sopWorkPermitId = '';
      this.searchParam.permitNo = '';
    },
  }
};
</script>
